import Axios from "axios";
import { Environment } from "../../constant/app";

class BackendApi {
  constructor() {
    this.axios = Axios.create({
      baseURL: `${Environment.REACT_APP_API_URL}`,
    });
    // Add a response interceptor
    this.axios.interceptors.response.use(
      (response) => {
        // If the request succeeds, return the response
        console.log({ apple: response });
        return response;
      },
      (error) => {
        // Check if the error is due to JWT expiration
        if (error.response && error.response.data.message === "jwt expired") {
          // If JWT expired, reload the page or perform any desired action
          window.location.reload(); // Reload the page
        }
        // If it's not a JWT expired error, return the error
        return Promise.reject(error);
      }
    );
  }

  getViewStat(resource) {
    return this.axios.get(`/insight/${resource}`);
  }

  getApplicationConfig(app) {
    return this.axios.get(`/applicationConfig/${app}`);
  }

  getChatbotParameters(chatbotId) {
    return this.axios.get(`/webChatbot/${chatbotId}/parameter`);
  }

  deleteUser() {
    return this.axios.delete(`/user`);
  }
  updateNotification(data) {
    return this.axios.put(`/user/notification`, data);
  }

  getDownloadables(downloadableConstId) {
    return this.axios.get(
      `/downloadable-const/${downloadableConstId}/downloadable/user`
    );
  }

  getExtensionConnections(extensionConstId) {
    return this.axios.get(`/extension-const/${extensionConstId}/extension`);
  }
  getExtension(extensionConstId) {
    return this.axios.get(`/extension-const/${extensionConstId}`);
  }

  deleteExtensionConnection(extensionConstId, id) {
    return this.axios.put(
      `/extension-const/${extensionConstId}/extension/${id}`,
      {
        isDeleted: true,
      }
    );
  }

  cloneExtensionConnection(id, extensionConstId) {
    return this.axios.post(
      `/extension-const/${extensionConstId}/extension/${id}/clone`,
      {}
    );
  }

  getOdooConnections(odooConnectionConstId) {
    return this.axios.get(`/odoo-const/${odooConnectionConstId}/odoo/user`);
  }

  getOdoo(odooConnectionConstId) {
    return this.axios.get(`/odoo-const/${odooConnectionConstId}`);
  }

  deleteOdooConnection(odooConnectionConstId, id) {
    return this.axios.put(`/odoo-const/${odooConnectionConstId}/odoo/${id}`, {
      isDeleted: true,
    });
  }

  cloneOdooConnection(id, odooConnectionConstId) {
    return this.axios.post(
      `/odoo-const/${odooConnectionConstId}/odoo/${id}/clone`,
      {}
    );
  }

  getProxyConnections(proxyConnectionConstId) {
    return this.axios.get(`/proxy-const/${proxyConnectionConstId}/proxy/user`);
  }

  getResourceFromWebcomponent(webcomponentId) {
    return this.axios.get(`/webComponents/${webcomponentId}/resource`);
  }

  deleteProxyConnection(proxyConnectionConstId, id) {
    return this.axios.put(
      `/proxy-const/${proxyConnectionConstId}/proxy/${id}`,
      {
        isDeleted: true,
      }
    );
  }

  cloneProxyConnection(id, proxyConnectionConstId) {
    return this.axios.post(
      `/proxy-const/${proxyConnectionConstId}/proxy/${id}/clone`,
      {}
    );
  }

  getPage(containerId) {
    return this.axios.get(`/site-proxy/containers/${containerId}`);
  }

  getPageWriters(userId, containerId) {
    return this.axios.get(
      `/site-proxy/container/${containerId}/user/${userId}/`
    );
  }

  deletePageRewriter(id) {
    return this.axios.put(`/site-proxy/${id}`, {
      isDeleted: true,
    });
  }

  clonePageRewriter(id) {
    return this.axios.post(`/site-proxy/${id}/clone`, {});
  }

  getWebComponent(webcomponentId) {
    return this.axios.get(`/webComponents/${webcomponentId}/`);
  }

  getWebComponentParameters(webcomponentId) {
    return this.axios.get(`/webComponents/${webcomponentId}/parameter/user`);
  }

  getWebComponentParameter(webcomponentId, parameterId) {
    return this.axios.get(
      `/webComponents/${webcomponentId}/parameter/${parameterId}`
    );
  }

  saveWebComponentParameter(webcomponentId, data) {
    return this.axios.post(`/webComponents/${webcomponentId}/parameter`, {
      ...data,
    });
  }

  editWebComponentParameter(webcomponentId, parameterId, data) {
    return this.axios.put(
      `/webComponents/${webcomponentId}/parameter/${parameterId}`,
      { ...data }
    );
  }

  deleteWebComponentParameters(id, webComponentId) {
    return this.axios.put(`/webComponents/${webComponentId}/parameter/${id}`, {
      isDeleted: true,
    });
  }

  cloneWebComponentParameters(id, webComponentId) {
    return this.axios.post(
      `/webComponents/${webComponentId}/parameter/${id}/clone`,
      {}
    );
  }

  getUserPlan(userId) {
    return this.axios.get(`/plan/user/${userId}`);
  }

  startTrialPlan(userId, plan, resource) {
    return this.axios.post(`/subscriptions/trial/${userId}`, {
      plan,
      resource,
    });
  }

  startFreeResource(userId, plan, resource, duration) {
    return this.axios.post(`/subscriptions/free/${userId}`, {
      plan,
      resource,
      duration,
    });
  }

  buyPlan({ email, userId, plan, duration }) {
    return this.axios.post(`/stripe/pay/plan`, {
      email,
      userId,
      plan,
      duration,
    });
  }

  buyResource({ email, userId, resource, duration }) {
    return this.axios.post(`/stripe/pay/resource`, {
      email,
      userId,
      resource,
      duration,
    });
  }

  purchasePlan({ planId, duration }) {
    return this.axios.post(`/purchase/session/plan/${planId}/${duration}`, {});
  }

  createResource({ resource, component }) {
    return this.axios.get("/purchase/create/resource", {
      params: {
        resource,
        component,
      },
    });
  }

  purchaseResource({ resourceId, duration, options }) {
    return this.axios.post(
      `/purchase/session/resource/${resourceId}/${duration}`,
      { ...options }
    );
  }

  cancelSubscription(id) {
    return this.axios.delete(`/purchase/subscription/${id}/cancel`, {});
  }
  getInvoice(id) {
    return this.axios.get(`/purchase/receipt/${id}`);
  }

  syncMarketPlaceResource() {
    return this.axios.post("/purchase/task/product/resource/sync", {});
  }

  editAPIKey(id, data) {
    return this.axios.put(`/apiKey/${id}`, data);
  }

  getBookings(resourceId) {
    return this.axios.get(`/extended/booking/${resourceId}`);
  }

  getAnalyticalWebComponentParameter(webcomponentParameter) {
    return this.axios.get(`/extended/component/${webcomponentParameter}`);
  }

  uploadImage({ category, folder, image }) {
    const formData = new FormData();
    formData.append("category", category);
    formData.append("folder", folder);
    formData.append("image", image);

    return this.axios.post(`/s3/upload/user`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getMarketPlaceResources(userId) {
    return this.axios.get(`/resource/user/${userId}`);
  }

  getAdminMarketPlaceResources() {
    return this.axios.get(`/resource/admin`);
  }

  getSubResources(resourceId) {
    return this.axios.get(`/resource/sub-resources/${resourceId}`);
  }

  switchMarketPlaceResource(resourceId) {
    return this.axios.put(`/resource/admin/switch/${resourceId}`);
  }

  getAddons(addons) {
    return this.axios.get("/resource/addons", {
      params: addons,
    });
  }

  toggleWebComponentPublishStatus(webComponentId, parameterId) {
    return this.axios.put(
      `/webComponents/${webComponentId}/parameter/${parameterId}/toggle-publish`
    );
  }

  verifyImpersonateUser(userId, loggedInUserId) {
    return this.axios.post("/user/Ips", { userId, loggedInUserId });
  }
}
const Api = new BackendApi();

export default Api;
