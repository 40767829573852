const REACT_APP_STRIPE_PUB_KEY = process.env.REACT_APP_STRIPE_PUB_KEY
const PUBLIC_URL = process.env.PUBLIC_URL
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
const REACT_APP_AZURE_AD_CLIENT_ID = process.env.REACT_APP_AZURE_AD_CLIENT_ID
const REACT_APP_BASENAME = process.env.REACT_APP_BASENAME
const REACT_APP_FULLFILLMENT_URL = process.env.REACT_APP_FULLFILLMENT_URL
const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL
const REACT_APP_PROXY_URL = process.env.REACT_APP_PROXY_URL
const REACT_APP_WEBCOMPONENT_TEST_SERVER = process.env.REACT_APP_WEBCOMPONENT_TEST_SERVER

export const Environment = {
  REACT_APP_STRIPE_PUB_KEY,
  PUBLIC_URL,
  REACT_APP_API_URL,
  REACT_APP_AZURE_AD_CLIENT_ID,
  REACT_APP_BASENAME,
  REACT_APP_FULLFILLMENT_URL,
  NEXT_PUBLIC_API_URL,
  REACT_APP_PROXY_URL,
  REACT_APP_WEBCOMPONENT_TEST_SERVER
}

export const Storage_Keys = {
  token: 'm365Token',
  userId: 'm365UserId',
  localAccountId: 'm365ULocalAccountId',
}

export const Common_Values = {
  TRIAL_PERIOD_RESOURCE_COUNT: 1
}
