import React, { useState, useEffect } from "react";
import { Alert, Button } from "reactstrap";

const Impersonate = () => {
  const [impsToken, setImpsToken] = useState(localStorage.getItem("ImpsToken"));
  const [impsUsername, setImpsUsername] = useState(
    localStorage.getItem("ImpsUserName")
  );

  const divStyle = {
    width: "100%",
    display: "flex",
    position: "fixed",
    bottom: 0, // Ensure the div is positioned at the bottom
    left: 0, // Ensure the div spans the full width
    justifyContent: "center", // Center content horizontally
    zIndex: 1000,
    padding: "10px", // Optional: Padding around the content
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Optional: Shadow for better visibility
  };

  const buttonStyle = {
    // Add button-specific styles here if needed
    // For example:
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  };
  const handleStop = () => {
    localStorage.removeItem("ImpsToken");
    localStorage.removeItem("ImpsUserName");
    setImpsToken(null);
    setImpsUsername(null);
    window.location.reload();
  };

  useEffect(() => {
    const token = localStorage.getItem("ImpsToken");
    const username = localStorage.getItem("ImpsUserName");
    setImpsToken(token);
    setImpsUsername(username);
  }, []);

  if (!impsToken || !impsUsername) {
    return null;
  }

  return (
    <div style={divStyle}>
      <Alert color="danger">
        <div className="alert-body heading-alert">
          <h4 className="alert-heading p-0 shadow-none lh-1">Admin note</h4>
          You are impersonating {impsUsername}
        </div>
        <div style={buttonStyle}>
          <Button onClick={handleStop}>Stop</Button>
        </div>
      </Alert>
    </div>
  );
};

export default Impersonate;
