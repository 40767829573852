// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import widget from "./widget";
import application from "./application";
import users from "./users";
import pricing from "./pricing-plans";
import transactions from "./transactions";
import builderSideBar from "./builder-sidebar";

const rootReducer = {
  auth,
  navbar,
  layout,
  widget,
  application,
  users,
  pricing,
  transactions,
  builderSideBar
};

export default rootReducer;
