import { createSlice } from "@reduxjs/toolkit";

const TransactionsSlicer = createSlice({
  name: "transactions",
  initialState: {
    transactions: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    getTransactions: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      transactions: [],
    }),
    getTransactionsSuccess: (state, action) => ({
      ...state,
      transactions: action.payload.data.data ?? [],
      isLoading: false,
      isSuccess: true,
      isError: false,
    }),
    getTransactionsFailed: (state) => ({
      ...state,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }),
  },
});

export const { getTransactionsFailed, getTransactions, getTransactionsSuccess } =
  TransactionsSlicer.actions;

export default TransactionsSlicer.reducer;
