import { put } from "redux-saga/effects";
import {
  getMyApplicationFailed,
  getMyApplicationSuccess,
  getApplicationFailed,
  getApplicationSuccess,
} from "../application";
import axios from "axios";
import { Environment } from "../../constant/app";

function* getApplicationSaga(action) {
  try {
    const response = yield axios.get(
      `${Environment.REACT_APP_API_URL}/script/${action.payload.homeAccountId}`
    );
    yield put(getApplicationSuccess(response));
  } catch (error) {
    console.log({ error });
    yield put(getApplicationFailed({ message: error.message }));
  }
}

function* getMyApplicationSaga(action) {
  try {
    const response = yield axios.get(
      `${Environment.REACT_APP_API_URL}/userScripts/${action.payload.homeAccountId}`
    );
    yield put(getMyApplicationSuccess(response));
  } catch (error) {
    console.log({ error });
    yield put(getMyApplicationFailed({ message: error.message }));
  }
}

export { getApplicationSaga, getMyApplicationSaga };
