import { takeLatest } from "redux-saga/effects";
import { getApplication, getMyApplication } from "../application";
import { getTransactions } from "../transactions";
import { getUsers } from "../users";
import { getPricing } from "../pricing-plans";
import { getMyWidget, getWidget, getCurrentWidget } from "../widget";
import { getApplicationSaga, getMyApplicationSaga } from "./application";
import { getTransactionsSaga } from "./transactions";
import { getUsersSaga } from "./users";
import { getPricingSaga } from "./pricing-plans";
import { getMyWidgetSaga, getWidgetSaga, getCurrentWidgetSaga } from "./widget";
import { updateNotification } from "../authentication";
import { updateNotificationSaga } from "./authenticationSaga";

export default function* watcherSagas() {
  yield takeLatest(getWidget.type, getWidgetSaga);
  yield takeLatest(getCurrentWidget.type, getCurrentWidgetSaga);
  yield takeLatest(getMyWidget.type, getMyWidgetSaga);
  yield takeLatest(getApplication.type, getApplicationSaga);
  yield takeLatest(getMyApplication.type, getMyApplicationSaga);
  yield takeLatest(getUsers.type, getUsersSaga);
  yield takeLatest(getPricing.type, getPricingSaga);
  yield takeLatest(getTransactions.type, getTransactionsSaga);
  yield takeLatest(updateNotification.type, updateNotificationSaga)
}
