import { createSlice } from "@reduxjs/toolkit";

const UsersSlicer = createSlice({
  name: "users",
  initialState: {
    users: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    getUsers: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      users: [],
    }),
    getUsersSuccess: (state, action) => ({
      ...state,
      users: action.payload.data.data ?? [],
      isLoading: false,
      isSuccess: true,
      isError: false,
    }),
    getUsersFailed: (state) => ({
      ...state,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }),
  },
});

export const { getUsersFailed, getUsers, getUsersSuccess } =
  UsersSlicer.actions;

export default UsersSlicer.reducer;
