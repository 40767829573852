import { createSlice } from "@reduxjs/toolkit";

const PriceSlicer = createSlice({
  name: "pricingPlans",
  initialState: {
    pricing: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    getPricing: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      pricing: [],
    }),
    getPricingSuccess: (state, action) => ({
      ...state,
      pricing: action.payload ?? [],
      isLoading: false,
      isSuccess: true,
      isError: false,
    }),
    getPricingFailed: (state) => ({
      ...state,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }),
  },
});

export const { getPricingFailed, getPricing, getPricingSuccess } =
  PriceSlicer.actions;

export default PriceSlicer.reducer;
