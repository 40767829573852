import { createSlice } from "@reduxjs/toolkit";

const BuilderSideBarSlicer = createSlice({
    name: "BuilderSideBarSlicer",
    initialState: {
        menuOpen: true,
        builderNav: 370,
        updatedProp: true
    },
    reducers: {
        getBuilderNav: (state, action) => ({
            ...state,
            builderNav:action.payload,
        }),
        setUpdatedProp: (state, action) => ({
            ...state,
            updatedProp:action.payload,
        }),
        setMenu: (state, action) => ({
            ...state,
            menuOpen:action.payload,
        })
    },
});

export const {
    getBuilderNav,
    setUpdatedProp,
    setMenu
} = BuilderSideBarSlicer.actions;

export default BuilderSideBarSlicer.reducer;
