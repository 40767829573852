import { put } from "redux-saga/effects";
import axios from "axios";
import { getTransactionsFailed, getTransactionsSuccess } from "../transactions";
import { Environment } from "../../constant/app";

function* getTransactionsSaga() {
  try {
    const response = yield axios.get(
      `${Environment.REACT_APP_API_URL}/stripe/getUserScripts`
    );
    yield put(getTransactionsSuccess(response));
  } catch (error) {
    yield put(getTransactionsFailed({ message: error.message }));
  }
}

export { getTransactionsSaga };
