import { createSlice } from "@reduxjs/toolkit";

const WidgetSlicer = createSlice({
  name: "widget",
  initialState: {
    widget: [],
    currentWidget: {
      loading: false,
      success: false,
      failed: '',
      data: null
    },
    myWidget: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    getWidget: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      widget: []
    }),
    getWidgetSuccess: (state, action) => ({
      ...state,
      widget: action.payload.data.data ?? [],
      isLoading: false,
      isSuccess: true,
      isError: false,
    }),
    getWidgetFailed: (state) => ({
      ...state,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }),
    getCurrentWidget: (state) => ({
      ...state,
      currentWidget: {
        ...state.currentWidget,
        loading: true,
        success: false,
      }
    }),
    getCurrentWidgetSuccess: (state, action) => ({
      ...state,
      currentWidget: {
        ...state.currentWidget,
        loading: false,
        success: true,
        data: action.payload.data.data
      }
    }),
    getCurrentWidgetFailed: (state) => {
      return {
        ...state,
        currentWidget: {
          ...state.currentWidget,
          loading: false,
          success: false,
          failed: state.payload.error
        }
      }
    },
    getMyWidget: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      myWidget: []
    }),
    getMyWidgetSuccess: (state, action) => ({
      ...state,
      myWidget: action.payload.data.data ?? [],
      isLoading: false,
      isSuccess: true,
      isError: false,
    }),
    getMyWidgetFailed: (state) => ({
      ...state,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }),
  },
});

export const {
  getWidgetFailed,
  getWidget,
  getWidgetSuccess,
  getCurrentWidgetFailed,
  getCurrentWidget,
  getCurrentWidgetSuccess,
  getMyWidget,
  getMyWidgetSuccess,
  getMyWidgetFailed,
} = WidgetSlicer.actions;

export default WidgetSlicer.reducer;
