import { put } from "redux-saga/effects";
import {
  getMyWidgetFailed,
  getMyWidgetSuccess,
  getWidgetFailed,
  getWidgetSuccess,
  getCurrentWidgetSuccess,
  getCurrentWidgetFailed,
} from "../widget";
import axios from "axios";
import { Environment } from "../../constant/app";

function* getWidgetSaga(action) {
  try {
    const response = yield axios.get(
      `${Environment.REACT_APP_API_URL}/webComponents/${action.payload.homeAccountId}`
    );
    yield put(getWidgetSuccess(response));
  } catch (error) {
    yield put(getWidgetFailed({ message: error.message }));
  }
}

function* getMyWidgetSaga(action) {
  try {
    const response = yield axios.get(
      `${Environment.REACT_APP_API_URL}/webComponents/user-web-components/${action.payload.homeAccountId}`
    );
    yield put(getMyWidgetSuccess(response));
  } catch (error) {
    console.log({error});
    yield put(getMyWidgetFailed({ message: error.message }));
  }
}
function* getCurrentWidgetSaga(action) {
  try {
    const response = yield axios.get(
      `${Environment.REACT_APP_API_URL}/webComponents/component/${action.payload.id}`
    );
    yield put(getCurrentWidgetSuccess(response));
  } catch (error) {
    console.log({error});
    yield put(getCurrentWidgetFailed({ message: error.message }));
  }
}

export { getWidgetSaga, getMyWidgetSaga, getCurrentWidgetSaga };
