import { put } from "redux-saga/effects";
import { getPricingSuccess, getPricingFailed } from "../pricing-plans";
import Api from "../../lib/backend";

function* getPricingSaga({ payload }) {
  try {
    const { data } = yield Api.getUserPlan(payload.userId);
    yield put(getPricingSuccess(data));
  } catch (error) {
    yield put(getPricingFailed({ message: error.message }));
  }
}

export { getPricingSaga };
