import { createSlice } from "@reduxjs/toolkit";

const ApplicationSlicer = createSlice({
  name: "application",
  initialState: {
    application: [],
    myApplication: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    getApplication: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      application: []
    }),
    getApplicationSuccess: (state, action) => ({
      ...state,
      application: action.payload.data ?? [],
      isLoading: false,
      isSuccess: true,
      isError: false,
    }),
    getApplicationFailed: (state) => ({
      ...state,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }),
    getMyApplication: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      myApplication: []
    }),
    getMyApplicationSuccess: (state, action) => ({
      ...state,
      myApplication: action.payload.data.response ?? [],
      isLoading: false,
      isSuccess: true,
      isError: false,
    }),
    getMyApplicationFailed: (state) => ({
      ...state,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }),
  },
});

export const {
  getApplicationFailed,
  getApplication,
  getApplicationSuccess,
  getMyApplication,
  getMyApplicationSuccess,
  getMyApplicationFailed,
} = ApplicationSlicer.actions;

export default ApplicationSlicer.reducer;
