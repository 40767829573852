import { put } from "redux-saga/effects";
import axios from "axios";
import { getUsersFailed, getUsersSuccess } from "../users";
import { Environment } from "../../constant/app";

function* getUsersSaga() {
  try {
    const response = yield axios.get(
      `${Environment.REACT_APP_API_URL}/user`
    );
    yield put(getUsersSuccess(response));
  } catch (error) {
    yield put(getUsersFailed({ message: error.message }));
  }
}

export { getUsersSaga };
