// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { Storage_Keys } from "../constant/app";

// ** UseJWT import to get config
// import useJwt from '@src/auth/jwt/useJwt'

// const config = useJwt.jwtConfig

const initialUser = () => {
  const item = localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : { isAdmin: false };
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
  },
  reducers: {
    handleLogin: (state, action) => {
      // state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      // state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem("userData", JSON.stringify(action.payload));
      state.userData = action.payload;
      // localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      // localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: (state) => {
      state.userData = {};
      // state[config.storageTokenKeyName] = null
      // state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem(Storage_Keys.token);
      localStorage.removeItem(Storage_Keys.userId);
      localStorage.removeItem(Storage_Keys.localAccountId);
      // localStorage.removeItem(config.storageTokenKeyName)
      // localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    updateNotification: (state, action) => {
      state.userData.notification = action.payload;
    },
  },
});

export const { handleLogin, handleLogout, updateNotification } =
  authSlice.actions;

export default authSlice.reducer;
